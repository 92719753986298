<template>
    <div class="sports_links">
        <router-link  tag="button" to="/sports_betinfo"> Sports</router-link>
        <router-link  to="/honorcasino_betinfo" tag="button">Casino</router-link>
        <router-link to="/jiligames_betinfo" tag="button">Jili Games</router-link>
        <!--        <router-link  to="/leisure_betinfo" tag="button">미니게임</router-link>-->
        <!--        <router-link to="/thirdpartgame_betinfo" tag="button">Tgame365</router-link>-->

    </div>
</template>

<script>
    export default {
        name: "BetInfoLinks"
    }
</script>

<style scoped>
    .sports_links button {
        border: 1px solid transparent;
        color: #ffffff;
        width: 33.2%;
    }
    @media screen and (max-width: 1024px) {
        .sports_links button {
            width: 33.2%;
        }
    }

</style>